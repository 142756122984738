

$primary: rgb(85, 118, 252);
$accordion-button-color: black;
$accordion-bg: white;

$accordion-button-active-color: black;
$accordion-button-active-bg: white;
$accordion-button-bg: none;
$accordion-color: white;
$accordion-border-color: $primary;
$accordion-border-width: 1px;
$accordion-button-focus-box-shadow: none;
$accordion-body-padding-x: 0px;
$accordion-body-padding-y: 0px;
$enable-shadows: true;
//$card-box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
$box-shadow: 0 .5rem 1rem rgba(black, .15);
$card-box-shadow: $box-shadow;

$popover-header-bg: $primary;
$popover-header-color: white;

.btn-outline-primary{
    &:hover{
        color: #fff !important;
    }
}

.button-primary {
    background-color: $primary;
    border: none;
    border-radius: 6px;
    padding: 7px 10px 7px 10px;
    margin-top: 0px;
    color: white;
    text-decoration: none;
    text-align: center;
}
.button-primary:hover {
    background-color: #ce3532;
    color: white;
}
.page-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
input[type=number] {
    -moz-appearance: textfield; /* Firefox */
}

.hidden-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.hidden-scrollbar::-webkit-scrollbar { /* Hide scrollbar for Chrome, Safari and Opera */
    display: none;
}

.unselectable-text {
    -webkit-touch-callout: none;
    -webkit-user-select: none; 
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none; 
    user-select: none; 
}

.fade-in-out-enter {
    opacity: 0;
}

.fade-in-out-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
}

.fade-in-out-exit {
    opacity: 1;
}

.fade-in-out-exit-active {
    opacity: 0;
    transition: opacity 500ms ease-in;
}

.slide-left-enter {
    opacity: 0.0;
    transform: translateX(-100px);
}
.slide-left-enter-active {
    opacity: 1.0;
    transform: translateX(0px);
    transition: opacity 300ms, transform 300ms;
}
.slide-left-exit {
    opacity: 1.0;
    transform: translateX(0px);
}
.slide-left-exit-active {
    opacity: 0.0;
    transform: translateX(-100px);
    transition: opacity 300ms, transform 300ms;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-grab {
    cursor: grab;
}

.invisible {
    opacity: 0;
}

.login-background {
    background-image: url('../assets/mtbTiled.png');
}

.desktop-block {
    display: block;

    @media (max-width: 850px) {
       display: none;
    }
}

.desktop-flex {
    display: flex;

    @media (max-width: 850px) {
       display: none;
    }
}

.mobile-block {
    display: none;

    @media (max-width: 850px) {
       display: block;
    }
}

.mobile-flex {
    display: none;

    @media (max-width: 850px) {
       display: flex;
    }
}


@import '../node_modules/bootstrap/scss/bootstrap.scss';

