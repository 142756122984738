

.custom-thumb {
    cursor: grab;
    color: white;
    border: 1px solid white;
    background-color: var(--bs-primary);
    border-radius: 8px;
    width: 16px;
    height: 16px;
    position: absolute;
    left: -8px;
    top: -8px;
    padding: 0px 4px 0px 4px;
}

.custom-thumb > div {
    position: absolute;
    left: 20px;
    top: 1px;
    border-radius: 3px;
    background-color: var(--bs-primary);
    padding: 0px 4px 0px 4px;
    font-size: 11px;
    line-height: 1;
    
}

.custom-thumb:active > div, .custom-thumb:hover > div {
    font-size: 20px;
    top: -3px;
    border-radius: 6px;
    line-height: normal;
}


.custom-slider-mark {
    background-color: var(--bs-primary);
    width: 6px;
    height: 2px;
    position: absolute;
    left: -3px;
    top: 20px;
    
}


.custom-slider-track {
    left: -2px;
    right: -2px;
    display: flex;
    flex-direction: column;
    align-items: center;
    
}

/* style={{color: 'white', backgroundColor: 'var(--bs-primary)', borderRadius: 6, position: 'absolute', top: -12, bottom: -12, padding: '0px 4px 0px 4px'}} */