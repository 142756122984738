/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

.nav-bar-wrapper {
    position: relative;
    background-color: white;
    height: 100vh;
    width: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 8px;
    padding-bottom: 8px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5);
    transition: width 300ms ease-out;
}
.nav-bar-wrapper-collapsed {
    width: 0px;
    padding: 0px;
    
}
.nav-bar-collapse-button {
    position: absolute;
    left: auto;
    right: -12px;
    
    bottom: 45%;
    height: 100px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    background-color: var(--bs-primary);
    color: white;
    border: none;
    padding: 2px;
}

/* .nav-bar-collapse-button-collapsed {
    position: fixed;
    left: 0px;
    right: auto;
} */

#nav-bar-company-drop-down {
    
    color: white;
    font-size: 12px;
    margin-bottom: 8px;
    width: 100%;
    
}
#nav-menu-wrapper {
    overflow-x: hidden;
}
#nav-menu-wrapper::-webkit-scrollbar {
    display: none;
}

.nav-bar-button-mobile {
    text-decoration: none;
    display: flex;
    gap: 8px;
    align-items: center;
    height: 100%;
    padding: 12px;
    color: var(--bs-primary);
    
}
.nav-bar-button-mobile-active {
    background-color: var(--bs-primary);
    color: white;
}

.nav-bar-button-mobile:hover {
    opacity: 0.66;
}

#logo {
    max-width: 100%;
    height: auto;
    margin-bottom: 8px;
}
#footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 4px;
}

